var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            { staticClass: "cardClassDetailInfo", attrs: { noHeader: true } },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "안전작업표준 작업명",
                        value: _vm.param.sopName,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: { title: "LBLPLANT", value: _vm.param.plantName },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "단위공정",
                        value: _vm.param.processName,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "안전작업표준 관리번호",
                        value: _vm.param.sopNo,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-2" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "작업구분",
                        value: _vm.param.workClassName,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-2" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "작업유형",
                        value: _vm.param.workTypeName,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-2" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "작업형태",
                        value: _vm.param.workKindName,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-2" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "위험등급",
                        value: _vm.param.hazardGradeName,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-1" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "제(개)정번호",
                        value: _vm.param.revisionNum,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-1" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "제(개)정자",
                        value: _vm.param.regUserName,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-2" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "제(개)정일시",
                        value: _vm.param.regDtStr,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "작업단계",
                tableId: "riskHazrd02",
                columnSetting: false,
                usePaging: false,
                filtering: false,
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                selection: "multiple",
                rowKey: "jobStepId",
                editable: _vm.editable,
              },
              on: { uploadChange: _vm.filechange, rowClick: _vm.rowClick },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props }) {
                    return [
                      [
                        props.row.editFlag !== "C"
                          ? _c(
                              "q-chip",
                              {
                                staticStyle: {
                                  "margin-bottom": "4px !important",
                                  width: "30px",
                                },
                                attrs: {
                                  color: "blue",
                                  clickable: true,
                                  "text-color": "white",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return (() => {
                                      _vm.rowClick(props.row)
                                    }).apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v(" ▶ ")]
                            )
                          : _vm._e(),
                      ],
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "작업단계 추가", icon: "add" },
                            on: { btnClicked: _vm.addrow },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "LBLREMOVE", icon: "remove" },
                            on: { btnClicked: _vm.deleteStep },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "LBLSAVE", icon: "save" },
                            on: { btnClicked: _vm.saveStep },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
        [
          _c(
            "c-table",
            {
              ref: "gridhazard",
              attrs: {
                title: "작업단계별 유해위험요인",
                tableId: "gridhazard",
                columnSetting: false,
                usePaging: false,
                filtering: false,
                columns: _vm.gridhazard.columns,
                data: _vm.gridhazard.data,
                isFullScreen: false,
                merge: _vm.gridhazard.merge,
                selection: "multiple",
                rowKey: "sopStepHazardFactorId",
                editable: _vm.editable,
              },
              on: {
                innerBtnClicked: _vm.innerBtnClicked,
                uploadChange: _vm.filechange,
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "picture"
                        ? [
                            _c("q-btn", {
                              staticClass: "tableinnerBtn",
                              attrs: {
                                flat: "",
                                align: "center",
                                color: "blue-6",
                                label: "",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.openPicture(props, col)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function () {
                                      return [
                                        props.row.editFlag !== "C"
                                          ? [
                                              _c("q-btn", {
                                                attrs: {
                                                  unelevated: "",
                                                  round: "",
                                                  dense: "",
                                                  size: "10px",
                                                  color: "primary",
                                                  icon: "wallpaper",
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && _vm.rowSelected
                        ? _c("c-btn", {
                            attrs: { label: "유해위험요인추가", icon: "add" },
                            on: { btnClicked: _vm.addHazard },
                          })
                        : _vm._e(),
                      _vm.editable && _vm.rowSelected
                        ? _c("c-btn", {
                            attrs: { label: "LBLREMOVE", icon: "remove" },
                            on: { btnClicked: _vm.deleteHazard },
                          })
                        : _vm._e(),
                      _vm.editable && _vm.rowSelected
                        ? _c("c-btn", {
                            attrs: { label: "LBLSAVE", icon: "save" },
                            on: { btnClicked: _vm.saveHazard },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }