<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <div class="col-3">
            <c-label-text title="안전작업표준 작업명" :value="param.sopName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="LBLPLANT" :value="param.plantName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="단위공정" :value="param.processName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="안전작업표준 관리번호" :value="param.sopNo"></c-label-text>
          </div>
          <div class="col-2">
            <c-label-text title="작업구분" :value="param.workClassName"></c-label-text>
          </div>
          <div class="col-2">
            <c-label-text title="작업유형" :value="param.workTypeName"></c-label-text>
          </div>
          <div class="col-2">
            <c-label-text title="작업형태" :value="param.workKindName"></c-label-text>
          </div>
          <div class="col-2">
            <c-label-text title="위험등급" :value="param.hazardGradeName"></c-label-text>
          </div>
          <div class="col-1">
            <c-label-text title="제(개)정번호" :value="param.revisionNum"></c-label-text>
          </div>
          <div class="col-1">
            <c-label-text title="제(개)정자" :value="param.regUserName"></c-label-text>
          </div>
          <div class="col-2">
            <c-label-text title="제(개)정일시" :value="param.regDtStr"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
      <c-table
        ref="table"
        title="작업단계"
        tableId="riskHazrd02"
        :columnSetting="false"
        :usePaging="false"
        :filtering="false"
        :columns="grid.columns"
        :data="grid.data"
        selection="multiple"
        rowKey="jobStepId"
        @uploadChange="filechange"
        :editable="editable"
        @rowClick="rowClick"
      >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="작업단계 추가" icon="add" @btnClicked="addrow" />
              <c-btn v-if="editable" label="LBLREMOVE" icon="remove" @btnClicked="deleteStep" />
              <c-btn v-if="editable" label="LBLSAVE" icon="save" @btnClicked="saveStep" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props }">
            <template>
              <q-chip
                v-if="props.row.editFlag !== 'C'"
                color="blue"
                :clickable="true"
                text-color="white"
                style="margin-bottom:4px !important; width:30px;"
                @click.stop="() => { rowClick(props.row) }">
                ▶
              </q-chip>
            </template>
          </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
      <c-table
        ref="gridhazard"
        title="작업단계별 유해위험요인"
        tableId="gridhazard"
        :columnSetting="false"
        :usePaging="false"
        :filtering="false"
        :columns="gridhazard.columns"
        :data="gridhazard.data"
        :isFullScreen="false"
        :merge="gridhazard.merge"
        selection="multiple"
        rowKey="sopStepHazardFactorId"
        @innerBtnClicked="innerBtnClicked"
        @uploadChange="filechange"
        :editable="editable"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && rowSelected" label="유해위험요인추가" icon="add" @btnClicked="addHazard" />
            <c-btn v-if="editable && rowSelected" label="LBLREMOVE" icon="remove" @btnClicked="deleteHazard" />
            <c-btn v-if="editable && rowSelected" label="LBLSAVE" icon="save" @btnClicked="saveHazard" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='picture'">
            <q-btn
              class="tableinnerBtn"
              flat
              align="center"
              color="blue-6"
              label=""
              @click.stop="openPicture(props, col)">
              <template v-slot:default >
                <template v-if="props.row.editFlag!=='C'">
                  <q-btn 
                    unelevated round dense
                    size="10px"
                    color="primary" 
                    icon="wallpaper" />
                </template>
              </template>
            </q-btn>
          </template>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'sop-step',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmSopId: '',
        plantName: '',
        sopName: '',
        sopNo: '',
        processName: '',
        hazardGradeName: '',
        workTypeName: '',
        workKindName: '',
        workClassName: '',
        revisionNum: '',
        regDtStr: ' ',
        regUserName: '',
        isRevPop: false,
        updateFlag: '',
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'jobStepNo',
            field: 'jobStepNo',
            label: 'LBLSORTORDER',
            style: 'width:50px',
            type: 'number',
            align: 'center',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: 'LBLSELECT',
            align: 'center',
            style: 'width:50px',
            type: 'custom',
            sortable: false
          },
          {
            name: 'jobStepName',
            field: 'jobStepName',
            label: '작업단계 명',
            type: 'textarea',
            align: 'left',
            sortable: false,
          },
          {
            name: 'jobStepDesc',
            field: 'jobStepDesc',
            label: '작업단계 설명',
            align: 'left',
            type: 'textarea',
            sortable: false,
          },
          // {
          //   name: 'saftyWorkMethod',
          //   field: 'saftyWorkMethod',
          //   label: '안전작업방법',
          //   align: 'left',
          //   type: 'textarea',
          //   sortable: false,
          // },
          {
            name: 'saftyShied',
            field: 'saftyShied',
            label: '안전보호구',
            align: 'left',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'useTool',
            field: 'useTool',
            label: '사용공구',
            align: 'left',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'caution',
            field: 'caution',
            label: '안전작업방법(주의사항)',
            align: 'left',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:70px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'attach',
            field: 'attach',
            label: '도해(사진/그림)',
            align: 'center',
            type: 'attach',
            style: 'width:160px',
            taskClassCd: 'SOP_STEP_PHOTO',
            keyText: 'jobStepId',
            sortable: false,
          },
        ],
        data: [],
      },
      gridhazard: {
        merge: [
          { index: 0, colName: 'riskHazardName' },
          { index: 1, colName: 'riskHazardName' },
        ],
        columns: [
          {
            name: 'ramRiskHazardClassName',
            field: 'ramRiskHazardClassName',
            label: '유해위험요인분류',
            align: 'center',
            style: 'width:140px',
            sortable: false,
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            label: '유해위험요인',
            align: 'center',
            sortable: false,
            innerBtn: true,
            btns: [
              { icon: 'add', }
            ]
          },
          {
            name: 'hazardOccurReason',
            field: 'hazardOccurReason',
            label: '원인',
            align: 'left',
            style: 'width:150px',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'hazardOccurResult',
            field: 'hazardOccurResult',
            label: '결과',
            align: 'left',
            style: 'width:150px',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'picture',
            field: 'picture',
            label: '유해위험요인<br/>사진',
            align: 'center',
            type: 'custom',
            style: 'width:90px',
            sortable: false,
          },
        ],
        height: '500px',
        data: [],
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      detailUrl: '',
      saveRUrl: '',
      deleteRUrl: '',
      rowSelected: false,
      selectedStepId: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  watch: {
    'param.updateFlag'() {
      this.getList()
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      if (this.param.isRevPop) {
        this.editable = false;
      }
      // url setting
      this.listUrl = selectConfig.mdm.sop.step.list.url;
      this.saveUrl = transactionConfig.mdm.sop.step.save.url;
      this.deleteUrl = transactionConfig.mdm.sop.step.delete.url;
      this.detailUrl = selectConfig.mdm.sop.jobhazardreason.url;
      this.saveRUrl = transactionConfig.mdm.sop.hazard.save.url;
      this.deleteRUrl = transactionConfig.mdm.sop.hazard.delete.url;
      this.getList();
    },
    filechange() { // F 업로드, R 삭제
      this.$emit('updateSopInfo');
    },
    getList() {
      if (this.param.mdmSopId) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    saveStep() {
      let checkItem = ['jobStepNo', 'jobStepName', 'jobStepDesc']
      let isConti = true;
      this.$_.forEach(this.grid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '필수 입력값을 입력해 주세요. [코드, 코드명, 순번]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.grid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getList();
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.$emit('updateSopInfo');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    addrow() {
      this.grid.data.push({
        editFlag: 'C',
        jobStepId: uid(),
        mdmSopId: this.param.mdmSopId,
        jobStepNo: this.grid.data.length + 1,
        jobStepName: '',
        jobStepDesc: '',
        saftyWorkMethod: '',
        saftyShied: '',
        useTool: '',
        caution: '',
        useFlag: 'Y',
      })
    },
    deleteStep() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              let cnt = 0;
              this.$_.forEach(selectData, item => {
                if (item.editFlag !== 'C') {
                  cnt++;
                }
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
              if (cnt > 0) {
                this.$emit('updateSopInfo');
              }
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    rowClick(row) {
      this.getDetail(row);
    },
    getDetail(row) {
      if (row) {
        this.rowSelected = true;
        this.selectedStepId = row.jobStepId;
        this.$http.url = this.detailUrl;
        this.$http.type = 'GET';
        this.$http.param = {mdmSopId: row.mdmSopId, jobStepId: row.jobStepId};
        this.$http.request((_result) => {
          if (_result.data.length > 0) {
            if (_result.data[0].sopStepHazardFactorId !== null) {
              this.gridhazard.data = _result.data;
            } else {
              this.gridhazard.data = [];
            }
          } else {
            this.gridhazard.data = [];
          }
        },);
      }
    },
    addHazard() {
      this.popupOptions.title = '유해위험요인 검색';
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/hazard/riskHazardPop.vue'}`);
      this.popupOptions.width = '800px';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        for(let c = 0; c < data.length; c++) {
          this.gridhazard.data.push({
            editFlag: 'C',
            sopStepHazardFactorId: uid(),
            mdmSopId: this.param.mdmSopId,
            jobStepId: this.selectedStepId,
            mdmHazardFactorsId: data[c].ramRiskHazardId,
            riskHazardName: data[c].riskHazardName,
            ramRiskHazardClassCd: data[c].ramRiskHazardClassCd,
            ramRiskHazardClassName: data[c].ramRiskHazardClassName,
            hazardOccurReason: '',
            hazardOccurResult: '',
          });
        }
      }
    },
    saveHazard() {
      let checkItem = ['riskHazardName', 'hazardOccurReason', 'hazardOccurResult']
      let isConti = true;
      this.$_.forEach(this.gridhazard.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '필수 입력값을 입력해 주세요. [유해위험요인, 원인, 결과]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.gridhazard.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveRUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getDetail({mdmSopId: this.param.mdmSopId, jobStepId: this.selectedStepId});
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.$emit('updateSopInfo');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    deleteHazard() {
      let selectData = this.$refs['gridhazard'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteRUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getDetail({mdmSopId: this.param.mdmSopId, jobStepId: this.selectedStepId});
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              let cnt = 0;
              this.$_.forEach(selectData, item => {
                if (item.editFlag !== 'C') {
                  cnt++;
                }
                this.gridhazard.data = this.$_.reject(this.gridhazard.data, item);
              })
              this.$refs['gridhazard'].$refs['compo-table'].clearSelection();
              if (cnt > 0) {
                this.getDetail({mdmSopId: this.param.mdmSopId, jobStepId: this.selectedStepId});
                this.$emit('updateSopInfo');
              }
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    innerBtnClicked(col, props) {
      if (col.name === 'riskHazardName') {
        this.gridhazard.data.splice(props.rowIndex, 0, {
          editFlag: 'C',
          sopStepHazardFactorId: uid(),
          mdmSopId: this.param.mdmSopId,
          jobStepId: this.selectedStepId,
          mdmHazardFactorsId: props.row.mdmHazardFactorsId,
          riskHazardName: props.row.riskHazardName,
          ramRiskHazardClassName: props.row.ramRiskHazardClassName,
          hazardOccurReason: '',
          hazardOccurResult: '',
        });
      }
    },
    openPicture(props) {
      this.popupOptions.title = '유해위험요인 사진';
      this.popupOptions.param = {
        ramRiskHazardId: props.row.mdmHazardFactorsId,
        disabled: !this.rowSelected
      };
      this.popupOptions.target = () => import(`${'@/pages/common/hazard/riskHazardPicture.vue'}`);
      this.popupOptions.width = '30%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePicturePopup;
    },
    closePicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>